import React from 'react';
import SecondHeadline from '../components/atoms/SecondHeadline';
import ThirdHeadline from '../components/atoms/ThirdHeadline';
import Footer from '../components/organisms/Footer';
import Header from '../components/organisms/DefaultHeader';
import Text from '../components/atoms/Text';
import Link from '../components/atoms/Link';
import PageContainer from '../components/atoms/PageContainer';

export function Impressum() {
  return (
    <PageContainer>
      <div className="select-none">
        <a href="/">
          <Header title="Decide Now" subtitle="Einfach und schnell zu einer gemeinsamen Entscheidung kommen" />
        </a>
        <div className="mx-auto max-w-screen-2xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <SecondHeadline text="Impressum" />
          <Text>Moonshots GmbH wird vertreten durch:</Text>
          <Text>Geschäftsführer Alexander Gerhart</Text>
          <Text>Registergericht: Amtsgericht Charlottenburg</Text>
          <Text>Handelsregister Registernummer: HRB 157181B</Text>
          <Text>Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz: DE303760708</Text>

          <section className="mt-10">
            <ThirdHeadline text="Haftungshinweis" />
            <Text>
              Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für
              den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
            </Text>
          </section>

          <section className="mt-10">
            <ThirdHeadline text="Kontakt" />
            <Text>Auguststr. 2</Text>
            <Text>10117 Berlin</Text>
            <Text>Deutschland</Text>
            <div className="mt-5" />
            <div>
              <Link text="kontakt@moonshots.gmbh" href="mailto:kontakt@moonshots.gmbh" />
            </div>
            <div>
              <Link text="Tel: 03054772277" href="tel:03054772277" />
            </div>
          </section>

          <section className="mt-10">
            <ThirdHeadline text="Unser Dank" />
            <div className="flex items-center">
              <Text className="mr-2">Vielen Dank an </Text>
              <Link text="unDraw" href="https://undraw.co/" />
              <Text className="ml-2"> für die Bereitstellung der Illustrationen.</Text>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </PageContainer>
  );
}

export default Impressum;
