import React from 'react';
import { VotingItem } from '../../mockdata/votingItems';
import { SystemMessage } from './SystemMessage';
import { UserMessage } from './UserMessage';

interface Props {
  createdAt: Date;
  memberId: string | null;
  author: string;
  text?: string;
  messageType: 'text' | 'information' | 'dateNote' | 'voting' | 'votingOption' | 'votingWinner';
  displayAuthor?: boolean;
  displayTime?: boolean;
  alignment: 'r' | 'l';
  votingItem?: VotingItem;
  onVote(votingItemId: string): void;
  onUnvote(votingItemId: string): void;
  isCurrentVotingClosed: boolean;
}

export function ChatMessageNew({
  createdAt,
  memberId,
  author,
  text,
  messageType,
  alignment,
  displayAuthor,
  displayTime,
  votingItem,
  onVote,
  onUnvote,
  isCurrentVotingClosed,
}: Props) {
  if (author !== 'system') {
    return (
      <UserMessage
        createdAt={createdAt}
        author={author}
        text={text}
        messageType={messageType}
        alignment={alignment}
        displayAuthor={displayAuthor}
        displayTime={displayTime}
      />
    );
  }

  return (
    <SystemMessage
      text={text}
      messageType={messageType}
      votingItem={votingItem}
      memberId={memberId}
      onVote={onVote}
      onUnvote={onUnvote}
      isCurrentVotingClosed={isCurrentVotingClosed}
    />
  );
}

export default ChatMessageNew;
