import { VotingItem } from '../models/VotingItem';
import http from './httpService';

const apiUrl = `${process.env.REACT_APP_API_URL}/offers`;

export const getOffersNearby = async ({ lat, lng }: { lat: number; lng: number }): Promise<VotingItem[]> => {
  const { data }: any = await http.get(`${apiUrl}?lat=${lat}&lng=${lng}`);
  return data;
};

export default {
  getOffersNearby,
};
