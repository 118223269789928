import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { GroupCreationContextProvider } from './contexts/GroupCreationContext';
import Chat from './pages/Chat';
import DataProtection from './pages/DataProtection';
import GroupCreation from './pages/GroupCreation/GroupCreation';
import { Impressum } from './pages/Impressum';
import Landing from './pages/Landing';

const router = createBrowserRouter([
  {
    path: '/',
    children: [
      { path: 'chat/:groupId', element: <Chat /> },
      {
        path: 'create',
        element: (
          <GroupCreationContextProvider>
            <GroupCreation />
          </GroupCreationContextProvider>
        ),
      },
      { path: '/', element: <Landing /> },
      { path: '/imprint', element: <Impressum /> },
      { path: '/data_protection', element: <DataProtection /> },
    ],
  },
]);

export default router;
