import React from 'react';
import { twMerge } from 'tailwind-merge';

export interface InputType {
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  type?: string;
  name: string;
  id?: string;
}

export function Input({ value, onChange, placeholder, className, type = 'text', name, id }: InputType) {
  return (
    <input
      className={twMerge(
        'block w-full rounded-xl border border-gray-300 shadow-lg focus:border-primary focus:ring-primary text-lg py-2 px-4',
        className
      )}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      type={type}
      name={name}
      id={id}
    />
  );
}

export default Input;
