import React from 'react';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { VotingItem } from '../../models/VotingItem';
import StarRating from '../atoms/StarRating';

interface Props {
  votingItem: VotingItem;
}

function SystemMessageVotingWinner({ votingItem }: Props) {
  const renderPriceRange = (priceRange: number) => {
    let priceStr = '';
    for (let i = 0; i < priceRange; i += 1) {
      priceStr += '€';
    }
    return priceStr;
  };

  return (
    <div className="m-3">
      <div className="mx-auto bg-gradient-to-br from-[#c39334] via-[#ffefc7] to-[#b89335] rounded-lg h-64 overflow-hidden shadow-xl relative max-w-[500px] z-10">
        <div className="w-full h-1/2">
          <div className="w-full h-[30%] text-center flex justify-center items-center font-bold">Der Gewinner ist:</div>
          <img src={votingItem?.mainImageUrl} alt="alt" className="w-full h-[70%] object-cover" />
        </div>
        <div className="w-full h-1/2 flex flex-col px-4 py-2">
          <div className="w-full h-1/3 text-center text-lg font-bold">{votingItem.name}</div>
          <div className="w-full h-1/3 flex flex-row text-sm items-center">
            <div className="flex w-2/3 items-center">
              <StarRating rating={votingItem?.rating} />
              <div className="text-center ml-3">
                {votingItem.rating}/5 ({votingItem.userRatingsTotal}) by <FontAwesomeIcon icon={faGoogle} />
              </div>
            </div>
            <div className="w-1/3 text-center flex justify-center items-center">
              {renderPriceRange(votingItem.priceLevel)}
            </div>
          </div>
          <div className="w-full h-1/3 flex flex-row text-sm">
            <a
              className="w-full h-full text-center flex justify-center items-center text-blue-900 hover:underline"
              href={`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${votingItem.googlePlacesId}`}
              target="_blank"
              rel="noreferrer"
            >
              {votingItem.vicinity}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SystemMessageVotingWinner;
