import React from 'react';
import Footer from '../components/organisms/Footer';
import Header from '../components/organisms/DefaultHeader';
import SecondHeadline from '../components/atoms/SecondHeadline';
import PageContainer from '../components/atoms/PageContainer';
import ThirdHeadline from '../components/atoms/ThirdHeadline';
import Text from '../components/atoms/Text';
import StartVotingButton from '../components/molecules/StartVotingButton';

function Landing() {
  return (
    <PageContainer>
      <div className="mb-28">
        <div className="flex justify-end items-center">
          <StartVotingButton />
        </div>
        <Header title="Decide Now" subtitle="Einfach und schnell zu einer gemeinsamen Entscheidung kommen." />
        <img src="/static/images/agree-together.svg" alt="Abstimmung teilen" className="w-1/3 mx-auto" />
      </div>
      <Text>
        In dieser Beta-Version beschränken wir uns auf die Abstimmung über Restaurants. Wo wollt ihr als nächstes
        gemeinsam Mittagessen gehen? 😏
      </Text>

      <div className="mt-20 mb-10">
        <SecondHeadline className="text-center" text="Und so funktioniert's" />

        <section className="mt-12">
          <ThirdHeadline text="Vorschläge wählen" />
          <Text>Passende Vorschläge finden und für die Abstimmung auswählen</Text>
          <img src="/static/images/location-search.svg" alt="Vorschläge finden" className="w-1/2 mx-auto mt-10" />
        </section>
        <section className="mt-16">
          <ThirdHeadline text="Abstimmung teilen" />
          <Text>Teile deine Abstimmung mit deinen Freunden</Text>
          <img src="/static/images/invite-friends.svg" alt="Abstimmung teilen" className="w-1/2 mx-auto mt-10" />
        </section>
        <section className="mt-16">
          <ThirdHeadline text="Gemeinsam abstimmen" />
          <Text>Deine Freunde können nun für die Vorschläge abstimmen</Text>
          <img src="/static/images/vote-for.svg" alt="Gemeinsam abstimmen" className="w-1/2 mx-auto mt-10" />
        </section>
        <section className="mt-16">
          <ThirdHeadline text="Spaß haben" />
          <Text>Habt Spaß mit eurer gemeinsamen Entscheidung</Text>
          <img src="/static/images/enjoy-together.svg" alt="Spaß haben" className="w-1/2 mx-auto mt-10" />
        </section>
        <section className="mt-16">
          <ThirdHeadline text="Jetzt starten" />
          <Text>Probiere es gleich aus. Wo wollt ihr gemeinsam Essen gehen?</Text>
          <div className="flex justify-center mt-10">
            <StartVotingButton />
          </div>
        </section>
      </div>

      <Footer />
    </PageContainer>
  );
}

export default Landing;
