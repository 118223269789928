import React from 'react';
import { twMerge } from 'tailwind-merge';

export function ThirdHeadline({ text, className = '' }: { text: string; className?: string }) {
  return (
    <h2
      className={twMerge(
        'mt-1 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl lg:text-4xl mb-4',
        className
      )}
    >
      {text}
    </h2>
  );
}

export default ThirdHeadline;
