import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../atoms/Button';

export function StartVotingButton() {
  const navigate = useNavigate();

  return <Button name="Neue Abstimmung" onClick={() => navigate('./create')} />;
}

export default StartVotingButton;
