import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import PageContainer from '../../../components/atoms/PageContainer';
import Header from '../../../components/organisms/DefaultHeader';
import Footer from '../../../components/organisms/Footer';
import Button from '../../../components/atoms/Button';
import Input from '../../../components/atoms/Input';

type Props = {
  onSubmit({ groupName }: { groupName: string }): void;
};

export function GroupCreationSetName({ onSubmit }: Props) {
  return (
    <Formik
      initialValues={{ groupName: '' }}
      validationSchema={Yup.object().shape({
        groupName: Yup.string()
          .min(1, 'Bitte gebe einen Gruppennamen ein')
          .max(40, 'Bitte gebe nicht mehr als 40 Zeichen ein')
          .required('Bitte gebe einen Gruppennamen ein'),
      })}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ errors, touched }) => {
        const hasError = Boolean(errors.groupName && touched.groupName);
        return (
          <>
            <Form>
              <PageContainer className="h-[80vh]">
                <div className="flex justify-end">
                  <Button name="Weiter" type="submit" disabled={hasError} />
                </div>
                <a href="/">
                  <Header title="Decide Now" subtitle="Wie soll deine Abstimmung heißen?" />
                </a>
                <div className="flex justify-center flex-wrap">
                  {/* <Field
                    className="border border-primary px-2 py-1 rounded-xl focus:outline-none text-primary basis-full text-lg"
                    name="groupName"
                    autoComplete="off"
                  /> */}
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  {/* <Field name="groupName">
                    {({ field }: any) => (
                      <Input
                        name={field.name}
                        placeholder="Gruppenname"
                        onChange={field.onChange}
                        value={field.value}
                      />
                    )}
                  </Field> */}
                  <Field name="groupName" as={Input} />
                  {hasError && (
                    <div className="mt-2 text-base text-red-600 text-primary basis-full text-center">
                      {errors.groupName}
                    </div>
                  )}
                </div>
              </PageContainer>
            </Form>
            <Footer />
          </>
        );
      }}
    </Formik>
  );
}

export default GroupCreationSetName;
