import React from 'react';
import ChatItemInformation from '../atoms/ChatItemInformation';
import SystemMessageVotingItem from './SystemMessageVotingItem';
import SystemMessageVotingWinner from './SystemMessageVotingWinner';

interface Props {
  text?: string;
  messageType: 'text' | 'information' | 'dateNote' | 'voting' | 'votingOption' | 'votingWinner';
  votingItem?: any;
  memberId: string | null;
  onVote(votingItemId: string): void;
  onUnvote(votingItemId: string): void;
  isCurrentVotingClosed: boolean;
}

export function SystemMessage({
  text,
  messageType,
  votingItem,
  memberId,
  onVote,
  onUnvote,
  isCurrentVotingClosed,
}: Props) {
  if (messageType === 'votingOption') {
    const hasVotedFor = votingItem.submittedVotes.find((vote: any) => vote.memberId === memberId);
    return (
      <SystemMessageVotingItem
        votingItem={votingItem}
        btnText={['Dafürstimmen', 'Zurücknehmen']}
        onBtnClick={() => (hasVotedFor ? onUnvote(votingItem.id) : onVote(votingItem.id))}
        selected={hasVotedFor}
        isActive={!isCurrentVotingClosed}
      />
    );
  }
  if (messageType === 'information') return <ChatItemInformation information={text} />;
  if (messageType === 'votingWinner') return <SystemMessageVotingWinner votingItem={votingItem} />;

  return <div className="mx-auto text-primary">{text}</div>;
}

export default SystemMessage;
