import React, { useEffect, useState } from 'react';
import Button from '../../../components/atoms/Button';
import PageContainer from '../../../components/atoms/PageContainer';
import Footer from '../../../components/organisms/Footer';
import Combobox from '../../../components/molecules/Combobox';
import Header from '../../../components/organisms/DefaultHeader';

export function CreateGroupLocationStep({
  locationSuggestions,
  setSearchLocation,
  setLocationSearchText,
  setNextCreationStep,
  location,
}: {
  locationSuggestions: { id: string; label: string; lat: number; lng: number }[];
  setSearchLocation(searchLocation: { lat: number; lng: number }): void;
  setLocationSearchText({ text }: { text: string }): void;
  setNextCreationStep(): void;
  location: { lat: number; lng: number } | null;
}) {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (location) {
      setHasError(false);
    }
  }, [location]);

  const handleContinue = () => {
    if (location) {
      setNextCreationStep();
    } else {
      setHasError(true);
    }
  };

  return (
    <>
      <PageContainer className="h-[80vh]">
        <div className="flex justify-end">
          <Button name="Weiter" onClick={handleContinue} disabled={hasError} />
        </div>
        <a href="/">
          <Header title="Decide Now" subtitle="Wo möchtest du nach Vorschlägen suchen?" />
        </a>
        <div>
          <Combobox options={locationSuggestions} onSelect={setSearchLocation} onInput={setLocationSearchText} />
          {hasError && (
            <div className="mt-2 text-base text-red-600 basis-full text-center">
              Bitte wähle einen Ort für die weitere Suche.
            </div>
          )}
        </div>
      </PageContainer>
      <Footer />
    </>
  );
}

export default CreateGroupLocationStep;
