import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { GroupChatContextProvider } from './contexts/GroupChatContext';
import router from './router';

export default function App() {
  return (
    <GroupChatContextProvider>
      <RouterProvider router={router} />
    </GroupChatContextProvider>
  );
}
