import React from 'react';

import { TimeDisplay, UserMessageText } from '../atoms';

interface Props {
  createdAt: Date;
  author: string;
  messageType: 'text' | 'information' | 'dateNote' | 'voting' | 'votingOption' | 'votingWinner';
  text?: string;
  displayAuthor?: boolean;
  displayTime?: boolean;
  alignment: 'r' | 'l';
}

export function UserMessage({ createdAt, author, messageType, displayAuthor, alignment, displayTime, text }: Props) {
  const renderContent: any = () => {
    switch (messageType) {
      case 'text':
        return <UserMessageText text={text} />;
      default:
        break;
    }
    return <div>{messageType}</div>;
  };

  return (
    <div
      className={`w-fit max-w-[66vw] bg-white text-primary shadow-lg rounded-xl ${
        alignment === 'r' ? 'ml-auto mr-3' : 'ml-3 mr-auto'
      } my-[0.1rem] ${displayAuthor ? 'mt-3' : ''} select-text px-2 z-10`}
    >
      {displayAuthor ? <h3 className="m-1 text-lg font-semibold">{author}</h3> : ''}
      {renderContent()}
      {displayTime ? <TimeDisplay time={createdAt} /> : ''}
    </div>
  );
}

export default UserMessage;
