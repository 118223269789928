import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import IconButton from '../atoms/IconButton';
import ChatMessageNew from '../molecules/ChatMessageNew';
import ShareGroupModal from '../organisms/ShareGroupModal';
import ChatHeader from '../organisms/ChatHeader';

interface Props {
  memberId: string | null;
  assembledChatHistory: any[] | null;
  message(message: string): void;
  vote(votingItemId: string): void;
  unvote(votingItemId: string): void;
  isCurrentVotingClosed: boolean;
  closeVoting(): void;
  refChatContainer: React.RefObject<HTMLDivElement>;
  isScrollToChatEndInitialized: boolean;
  refChatMessageInput: React.RefObject<HTMLInputElement>;
  innerHeight: number;
  groupShareModalIsOpen: boolean;
  toggleGroupShareModal(): void;
  groupName: string | null;
}

export function ChatTemplate({
  assembledChatHistory,
  message,
  memberId,
  innerHeight,
  isCurrentVotingClosed,
  toggleGroupShareModal,
  refChatContainer,
  isScrollToChatEndInitialized,
  groupName,
  closeVoting,
  vote,
  unvote,
  refChatMessageInput,
  groupShareModalIsOpen,
}: Props) {
  return (
    <div className="flex flex-col select-none" style={{ height: `${innerHeight}px` }}>
      <ChatHeader
        groupName={groupName}
        isCurrentVotingClosed={isCurrentVotingClosed}
        closeVoting={closeVoting}
        toggleGroupShareModal={toggleGroupShareModal}
      />
      <div
        ref={refChatContainer}
        className={`grow w-full overflow-scroll flex flex-col${
          !isScrollToChatEndInitialized ? ' invisible' : ''
        } bg-gray-100`}
      >
        {assembledChatHistory &&
          assembledChatHistory.map((chatItem, index, arr) => {
            const alignment: 'r' | 'l' = chatItem.memberId === memberId ? 'r' : 'l';
            return (
              <ChatMessageNew
                key={chatItem.id}
                createdAt={chatItem.createdAt}
                memberId={memberId}
                author={chatItem.author}
                messageType={chatItem.messageType}
                text={chatItem.text}
                votingItem={chatItem.votingItem}
                alignment={alignment}
                displayAuthor={
                  // eslint-disable-next-line no-nested-ternary
                  alignment === 'l' ? (index > 1 ? arr[index - 1].memberId !== chatItem.memberId : true) : false
                }
                displayTime={
                  index > 1
                    ? Math.abs(chatItem.createdAt.getTime() - arr[index - 1].createdAt.getTime()) > 60000 ||
                      chatItem.createdAt.getMinutes() !== arr[index - 1].createdAt.getMinutes()
                    : true
                }
                onVote={vote}
                onUnvote={unvote}
                isCurrentVotingClosed={isCurrentVotingClosed}
              />
            );
          })}
      </div>

      <div className="shadow-xl">
        <Formik
          initialValues={{ newChatMessage: '' }}
          onSubmit={({ newChatMessage }, { resetForm }) => {
            message(newChatMessage);
            resetForm();
          }}
          validationSchema={yup.object().shape({
            newChatMessage: yup.string().min(1).required('Bitte gib eine Nachricht ein'),
          })}
        >
          {({ handleSubmit, isSubmitting, errors }) => (
            <Form className="pt-4 pb-6">
              <div className="w-full flex px-3 justify-between sticky bottom-0">
                <Field
                  innerRef={refChatMessageInput}
                  // TODO: design if disabled
                  disabled={isSubmitting}
                  name="newChatMessage"
                  className="text-lg bg-gray-50 border border-gray-300 text-gray-900 rounded-xl focus:ring-primary focus:border-primary block w-full p-2.5 mr-4 shadow-lg"
                  type="text"
                  autoComplete="off"
                  placeholder="Neue Nachricht"
                />
                <IconButton
                  className="h-12 w-12 text-lg"
                  icon={<FontAwesomeIcon icon={faPaperPlane} />}
                  disabled={isSubmitting || !!errors.newChatMessage}
                  onClick={handleSubmit}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {groupShareModalIsOpen && <ShareGroupModal onClose={toggleGroupShareModal} groupName={groupName} />}
    </div>
  );
}

export default ChatTemplate;
