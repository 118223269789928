import React, { createContext, ReactElement, useContext, useEffect, useState, useMemo } from 'react';
import io, { Socket } from 'socket.io-client';

interface GroupChatContextInterface {
  connected: boolean;
  connect(groupId: string): void;
  disconnect(): void;
  isNewToGroup: boolean | null;
  join(userName: string): void;
  name: string | null;
  members: object[] | null;
  userName: string | null;
  memberId: string | null;
  currentVoting: object | null;
  recentVotings: object[] | null;
  messages: object[] | null;
  assembledChatHistory: any[] | null;
  message(message: string): void;
  vote(votingItemId: string): void;
  unvote(votingItemId: string): void;
  isCurrentVotingClosed: boolean;
  closeVoting(): void;
  refChatContainer: React.RefObject<HTMLDivElement>;
  isScrollToChatEndInitialized: boolean;
  refChatMessageInput: React.RefObject<HTMLInputElement>;
  innerHeight: number;
  groupShareModalIsOpen: boolean;
  toggleGroupShareModal(): void;
  groupId: string | null;
}

export const GroupChatContext = createContext<GroupChatContextInterface>({} as GroupChatContextInterface);

type Props = {
  children: ReactElement;
};

let socket: Socket | null = null;

export function GroupChatContextProvider({ children }: Props) {
  const [groupId, setGroupId] = useState<string | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [isNewToGroup, setIsNewToGroup] = useState<boolean | null>(null);
  const [userName, setUserName] = useState<string | null>(null);
  const [personalAccessToken, setPersonalAccessToken] = useState<string | null>(null);
  const [memberId, setMemberId] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [members, setMembers] = useState<object[] | null>(null);
  const [currentVoting, setCurrentVoting] = useState<any | null>(null);
  const [recentVotings, setRecentVotings] = useState<object[] | null>(null);
  const [messages, setMessages] = useState<object[] | null>(null);
  const [assembledChatHistory, setAssembledChatHistory] = useState<any[] | null>(null);
  const [isScrollToChatEndInitialized, setIsScrollToChatEndInitialized] = useState(false);
  const [isCurrentVotingClosed, setIsCurrentVotingClosed] = useState(false);
  const [innerHeight, setInnerHeight] = useState<number>(window.innerHeight);
  const [groupShareModalIsOpen, setGroupShareModalIsOpen] = useState<boolean>(false);

  const refChatContainer = React.createRef<HTMLDivElement>();
  const refChatMessageInput = React.createRef<HTMLInputElement>();

  // use effects
  useEffect(() => {
    if (isConnected) {
      const isNewToGroup = localStorage.getItem(groupId as string) === null;
      setIsNewToGroup(isNewToGroup);

      if (!isNewToGroup) {
        const { userName, personalAccessToken, memberId } = JSON.parse(
          localStorage.getItem(groupId as string) as string
        );
        setUserName(userName);
        setMemberId(memberId);
        setPersonalAccessToken(personalAccessToken);
      }
    }
  }, [groupId, isConnected]);

  useEffect(() => {
    if (!isNewToGroup && personalAccessToken && groupId) {
      socket?.emit('requestGroupData', { groupId, personalAccessToken });
    }
  }, [isNewToGroup, personalAccessToken, groupId]);

  useEffect(() => {
    // assemble chat history
    if (messages && currentVoting && members) {
      const assembledMessages = messages.map((message: any) => ({
        ...message,
        memberId: message.memberId,
        author: message.memberName,
        messageType: 'text',
        createdAt: new Date(message.createdAt),
      }));
      const assembledVotingItems = currentVoting.votingItems.map(({ id, createdAt, ...restOfVotingItem }: any) => ({
        id,
        author: 'system',
        messageType: 'votingOption',
        createdAt: new Date(createdAt),
        votingItem: {
          id,
          ...restOfVotingItem, // TODO: remove when full data from api is available
        },
      }));
      const assembledInfos = members.map(({ id, createdAt, name }: any) => ({
        id,
        text: `${name} ist beigetreten`,
        createdAt: new Date(createdAt),
        author: 'system',
        messageType: 'information',
      }));

      const { wonByVotingItemId } = currentVoting;
      if (wonByVotingItemId && currentVoting.status === 'CLOSED') {
        const wonByVotingItem = currentVoting.votingItems.find(
          (votingItem: any) => votingItem.id === wonByVotingItemId
        );

        const votingWinner = {
          id: currentVoting.id,
          author: 'system',
          messageType: 'votingWinner',
          createdAt: new Date(currentVoting.updatedAt),
          votingItem: {
            id: wonByVotingItem.id,
            ...wonByVotingItem, // TODO: remove when full data from api is available
          },
        };
        assembledVotingItems.push(votingWinner);

        const closedByMember: any = members.find((member: any) => member.id === currentVoting.ClosedByMemberId);
        const closedOn = new Date(
          new Date(currentVoting.updatedAt).setMilliseconds(new Date(currentVoting.updatedAt).getMilliseconds() - 1)
        );
        assembledInfos.push({
          id: currentVoting.id,
          text: `${closedByMember.name} hat die Abstimmung beendet`,
          createdAt: closedOn,
          author: 'system',
          messageType: 'information',
        });
      }

      const assembledChatHistory = [...assembledMessages, ...assembledVotingItems, ...assembledInfos];
      assembledChatHistory.sort((a: any, b: any) => a.createdAt - b.createdAt);
      setAssembledChatHistory(assembledChatHistory);
    }
  }, [messages, currentVoting]);

  useEffect(() => {
    if (assembledChatHistory) {
      const { scrollHeight, clientHeight, scrollTop } = refChatContainer.current as HTMLDivElement;
      const totalScrollHeight = scrollHeight - clientHeight;

      if (totalScrollHeight - scrollTop < 100) {
        refChatContainer.current?.scrollTo(0, totalScrollHeight);
      }

      if (!isScrollToChatEndInitialized) {
        refChatContainer.current?.scrollTo(0, totalScrollHeight);
        setIsScrollToChatEndInitialized(true);
      }
    }
  }, [assembledChatHistory, isScrollToChatEndInitialized]);

  useEffect(() => disconnecSockets, []);

  useEffect(() => {
    window.addEventListener('resize', () => setInnerHeight(window.innerHeight), false);
  }, []);

  // making: connect, disconnect
  const connect = (groupId: string) => {
    socket = io(`${process.env.REACT_APP_API_URL}/groups`);

    setGroupId(groupId);

    socket.on('connect', () => {
      setIsConnected(true);
    });

    socket.on('getMyMemberData', (data: any) => {
      setUserName(data.userName);
      setPersonalAccessToken(data.personalAccessToken);
      setMemberId(data.memberId);

      localStorage.setItem(groupId as string, JSON.stringify({ ...data }));
      setIsNewToGroup(false);
    });

    socket.on('getGroupData', (data: any) => {
      console.log('getGroupData', data);
      setName(data.name);
      setMembers(data.members);
      setMessages(data.messages);
      setCurrentVoting(data.currentVoting);
      setRecentVotings(data.recentVotings);

      if (data.currentVoting.status === 'CLOSED') {
        setIsCurrentVotingClosed(true);
      }
    });

    socket.on('updateMembers', (data: { members: any[] }) => {
      setMembers(data.members);
    });

    socket.on('updateMessages', (data: { messages: any[] }) => {
      setMessages(data.messages);
    });

    socket.on('updateVoting', (data: any) => {
      setCurrentVoting(data.currentVoting);

      if (data.currentVoting.status === 'CLOSED') {
        setIsCurrentVotingClosed(true);
      }
    });
  };
  const disconnect = () => {
    disconnecSockets();
  };

  // participation: join, leave
  const join = (userName: string) => {
    socket?.emit('join', { groupId, userName });
  };
  // const leave = () => {
  //   socket?.emit("leave", { groupId, personalAccessToken });
  // };

  // chat: message
  const message = (message: string) => {
    socket?.emit('message', { groupId, message, personalAccessToken });
    refChatContainer.current?.scrollTo(0, refChatContainer.current.scrollHeight);
    refChatMessageInput.current?.focus(); // TODO: not working
  };

  const toggleGroupShareModal = () => {
    setGroupShareModalIsOpen((prev) => !prev);
  };

  // voting: vote, unvote, closeVoting
  const vote = (votingItemId: string) => {
    socket?.emit('vote', { groupId, votingItemId, personalAccessToken });
  };
  const unvote = (votingItemId: string) => {
    socket?.emit('unvote', { groupId, votingItemId, personalAccessToken });
  };
  const closeVoting = () => {
    socket?.emit('closeVoting', { groupId, personalAccessToken });
  };

  // error if function not available
  const errorIsNotConnected = () => {
    throw new Error('Not connected to group chat!');
  };
  const errorIsAlreadyConnected = () => {
    throw new Error('Already connected to group chat!');
  };

  const disconnecSockets = () => {
    socket?.disconnect();
    socket?.off('connect');
    socket?.off('getMyMemberData');
    socket?.off('getGroupData');
    socket?.off('updateMessages');
    socket?.off('updateVoting');
    setIsConnected(false);
  };

  const value = useMemo(
    () => ({
      connected: isConnected,
      connect: !isConnected ? connect : errorIsAlreadyConnected,
      disconnect: isConnected ? disconnect : errorIsNotConnected,
      isNewToGroup,
      join: isConnected ? join : errorIsNotConnected,
      name,
      members,
      userName,
      memberId,
      currentVoting,
      recentVotings,
      messages,
      assembledChatHistory,
      message: isConnected ? message : errorIsNotConnected,
      vote: isConnected ? vote : errorIsNotConnected,
      unvote: isConnected ? unvote : errorIsNotConnected,
      isCurrentVotingClosed,
      closeVoting: isConnected ? closeVoting : errorIsNotConnected,
      refChatContainer,
      isScrollToChatEndInitialized,
      refChatMessageInput,
      innerHeight,
      groupShareModalIsOpen,
      toggleGroupShareModal,
      groupId,
    }),
    [
      isConnected,
      connect,
      disconnect,
      isNewToGroup,
      join,
      name,
      members,
      userName,
      memberId,
      currentVoting,
      recentVotings,
      messages,
      assembledChatHistory,
      message,
      vote,
      unvote,
      isCurrentVotingClosed,
      closeVoting,
      refChatContainer,
      isScrollToChatEndInitialized,
      refChatMessageInput,
      innerHeight,
      groupShareModalIsOpen,
      toggleGroupShareModal,
      groupId,
    ]
  );

  return <GroupChatContext.Provider value={value}>{children}</GroupChatContext.Provider>;
}

export const useGroupChat = () => useContext(GroupChatContext);
