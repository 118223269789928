import React from 'react';

function VotingItemBtn({ selected, onBtnClick, btnText, disabled }: any) {
  const outerClassName = `bg-${selected ? 'primary' : 'white'} border border-primary rounded-md text-${
    selected ? 'white' : 'primary'
  } px-2 py-1 disabled:opacity-60`;
  return (
    <button disabled={disabled} type="button" className={outerClassName} onClick={() => onBtnClick()}>
      {selected ? btnText[1] : btnText[0]}
    </button>
  );
}

export default VotingItemBtn;
