import React from 'react';
import SecondHeadline from '../components/atoms/SecondHeadline';
import ThirdHeadline from '../components/atoms/ThirdHeadline';
import Header from '../components/organisms/DefaultHeader';
import Text from '../components/atoms/Text';
import Footer from '../components/organisms/Footer';
import PageContainer from '../components/atoms/PageContainer';

export function DataProtection() {
  return (
    <PageContainer>
      <div className="select-none">
        <a href="/">
          <Header title="Decide Now" subtitle="Einfach und schnell zu einer gemeinsamen Entscheidung kommen" />
        </a>
        <div className="mx-auto max-w-screen-2xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <SecondHeadline text="Datenschutzerklärung" />
          <Text>
            Die persönlichen Daten, die Sie uns im Rahmen des Besuchs und der Bedienung unserer Internetseite
            überlassen, unterliegen einem besonderen Schutz. Diese Datenschutzerklärung klärt Sie über den Zweck der
            Datenerfassung und Ihre diesbezüglichen Rechte auf.
          </Text>
          <section className="mt-10">
            <ThirdHeadline text="Verantwortlichkeit" />
            <Text>
              Verantwortlich für die Verarbeitung Ihrer persönlichen Daten im Rahmen dieser Einwilligung ist die
              Moonshots GmbH, Auguststr. 2 , 10117 Berlin, E-Mail: datenschutz@moonshots.gmbh
            </Text>
          </section>
          <section className="mt-10">
            <ThirdHeadline text="Zweck der Datenerfassung" />
            <Text>
              Beim Aufrufen von und Navigieren in unserer Internetseite wird für statistische Zwecke und zur zukünftigen
              Verbesserung unserer Internetseite Ihre IP-Adresse, zusammen mit anderen Daten, wie die aufgerufene Seite,
              der Zeitpunkt des Aufrufs, Browserkennung und Bildschirmauflösung erfasst. Sofern Sie unser
              Kontaktformular nutzen, werden die von Ihnen eingetragen Daten per E-Mail an uns übertragen. Diese Daten
              dienen zur Abwicklung und Bearbeitung Ihrer Anfrage.
            </Text>
          </section>
          <section className="mt-10">
            <ThirdHeadline text="Zweck der Verarbeitung" />
            <Text>
              Bei der Erstellung neuer Abstimmungen oder beim Beitritt zu laufenden Abstimmungen speichern wir im
              LocalStorage Ihres Browsers den von Ihnen festgelegten Nicknamen und von uns erstellte Tokens, damit Sie
              beim Abstimmen und Senden von Nachrichten identifiziert werden können. Zudem werden die Daten über die
              Abstimmungen als auch die Chatnachrichten in einer Datenbank auf unserem Server für begrenzte Zeit
              gespeichert.
            </Text>
          </section>
          <section className="mt-10">
            <ThirdHeadline text="Weitergabe Ihrer Daten" />
            <Text>
              Wir geben die Daten von Ihren Abstimmungen und Chatverläufen nur an Dritte weiter, wenn wir hierzu
              aufgrund geltenden Rechts berechtigt oder verpflichtet sind.
            </Text>
          </section>
          <section className="mt-10">
            <ThirdHeadline text="Speicherdauer" />
            <Text>
              Sofern Sie Teil einer Abstimmung sind, werden die Daten zu Ihrer Person wie Nickname und Zugriffstokens,
              Abstimmungsdetails und Chatnachrichten 72 Stunden nach dem Abschluss einer Abstimmung automatisch von
              unseren Datenbanken gelöscht. Sollte eine Abstimmung nicht abgeschlossen werden und nach einer Woche keine
              weitere Aktivität im Chat verzeichnet werden, so werden alle Daten einer Abstimmung ebenfalls vollständig
              gelöscht.
            </Text>
          </section>
          <section className="mt-10">
            <ThirdHeadline text="Recht auf Auskunft, Berichtigung, Löschung, Einschränkung und Übertragbarkeit" />
            <Text>
              Generell haben Sie das Recht, Auskunft darüber zu erhalten, welche Daten über Sie bei uns gespeichert sind
              und zu welchem Zweck die Speicherung erfolgt. Darüber hinaus sind Sie berechtigt, unrichtige Daten
              korrigieren oder löschen zu lassen, sofern die Speicherung nicht mehr erforderlich ist.
            </Text>
          </section>
          <section className="mt-10">
            <ThirdHeadline text="Sentry.io" />
            <Text>
              Unsere Website verwendet den Drittdienst Sentry.io zur Überwachung der Stabilität unserer Web- und
              Serveranwendungen. Sentry.io sammelt möglicherweise bestimmte Daten wie z.B. IP-Adresse, Browsertyp,
              Betriebssystem und Nutzungsdaten, um unsere Dienste optimal bereitstellen zu können. Wir geben keine
              persönlichen Daten an Sentry.io weiter und sind uns bewusst, dass dieser Dienst die
              Datenschutzbestimmungen einhalten muss. Für weitere Informationen über Sentry.io und deren
              Datenschutzbestimmungen, besuchen Sie bitte die entsprechende Website von Sentry.io. Die
              Datenverarbeitungsbedingungen (Data Processing Addendum) finden Sie unter: https://sentry.io/legal/dpa/.
              Zudem können Sie mehr über die Daten erfahren, die Sentry zur Verarbeitung erhebt und verarbeitet in deren
              Datenschutzerklärung: https://sentry.io/privacy/. Davon abgesehen können Sie der Verarbeitung Ihrer
              persönlichen Daten für Zwecke abseits der Bearbeitung Ihrer Anfrage explizit widersprechen. Sie haben das
              Recht, die über Sie gespeicherten Daten in einem maschinenlesbaren Format zu erhalten.
            </Text>
          </section>
          <section className="mt-10">
            <ThirdHeadline text="Mapbox Api" />
            <Text>
              Unsere Website verwendet den Dienst &quot;Mapbox&quot;, um dem Nutzer Vorschläge zur Ortseingabe zu machen
              und die Geo-Koordinaten des ausgewählten Ortes zu bestimmen. Mapbox ist ein Dienst der Mapbox Inc., dessen
              Sitz sich in den USA befindet. An die Mapbox API werden nur die von Ihnen eingegebenen Ortsangaben
              übermittelt, um die gewünschten Geo-Koordinaten zu erhalten. Außer den von Ihnen eingegebenen Ortsangaben
              werden keine weiteren Daten an Mapbox übermittelt.
            </Text>
          </section>
          <section className="mt-10">
            <ThirdHeadline text="Google Places Api" />
            <Text>
              Unsere Website verwendet den Dienst &quot;Google Places&quot;, um passende Angebote in der Nähe des von
              dem Nutzer ausgewählten Ortes zu finden. Google Places ist ein Dienst der Google LLC, dessen Sitz sich in
              den USA befindet. An die Google Places API senden wir im Backend nur die ermittelten Geo-Koordinaten des
              von dem Nutzer gewählten Ortes, um passende Angebote zu finden.
            </Text>
          </section>
          <section className="mt-10">
            <ThirdHeadline text="Recht auf Beschwerde" />
            <Text>Sie haben das Recht, sich bei einer Datenschutzaufsichtsbehörde zu beschweren.</Text>
          </section>
        </div>
      </div>
      <Footer />
    </PageContainer>
  );
}

export default DataProtection;
