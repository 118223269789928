import React from 'react';
import FirstHeadline from '../atoms/FirstHeadline';
import Text from '../atoms/Text';

export default function Header({ title, subtitle }: { title: string; subtitle: string }) {
  return (
    <div className="mb-16">
      <FirstHeadline className="text-start" text={title} />
      <Text className="text-start text-2xl" text={subtitle} />
    </div>
  );
}
