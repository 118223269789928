import React, { ReactNode } from 'react';
import { faStar, faStarHalfStroke } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function StarRating({
  // name,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  rating,
}: {
  rating: number;
}) {
  const icons = [];
  for (let i = 0; i < Math.floor(rating); i += 1) {
    icons.push(<FontAwesomeIcon icon={faStar} />);
  }
  if (rating % 1 < 0.25) {
    icons.push(<FontAwesomeIcon icon={faStarRegular} />);
  } else if (rating % 1 < 0.75) {
    icons.push(<FontAwesomeIcon icon={faStarHalfStroke} />);
  } else {
    icons.push(<FontAwesomeIcon icon={faStar} />);
  }
  while (icons.length < 5) {
    icons.push(<FontAwesomeIcon icon={faStarRegular} />);
  }
  return <div className="flex flex-row justify-center">{icons}</div>;
}

export default StarRating;
