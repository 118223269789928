import React from 'react';

interface Props {
  text?: string;
}

function UserMessageText({ text }: Props) {
  return <div className="m-1">{text}</div>;
}

export default UserMessageText;
