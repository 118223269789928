import React from 'react';

interface Props {
  time: Date;
}

function TimeDisplay({ time }: Props) {
  return (
    <div className="m-1 text-xs text-right">
      {time.getHours()}:{time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes()}
    </div>
  );
}

export default TimeDisplay;
