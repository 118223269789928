import { CreateGroupModel } from '../models/CreateGroupModel';
import http from './httpService';

const apiUrl = `${process.env.REACT_APP_API_URL}/groups`;

export const create = async (groupDataToCreate: CreateGroupModel): Promise<{ groupId: string }> => {
  const { data }: any = await http.post(apiUrl, groupDataToCreate);
  return data;
};

export default {
  create,
};
