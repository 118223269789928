import React from 'react';
import { twMerge } from 'tailwind-merge';

export function SecondHeadline({ text, className }: { text: string; className?: string }) {
  return (
    <h2
      className={twMerge(
        'mt-1 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl mb-5',
        className
      )}
    >
      {text}
    </h2>
  );
}

export default SecondHeadline;
