import React from 'react';

export function Link({ text, href = '#', target = '_self' }: { text: string; href?: string; target?: string }) {
  return (
    <a className="text-xl leading-8 text-blue-600 hover:underline" href={href} target={target}>
      {text}
    </a>
  );
}

export default Link;
