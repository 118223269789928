import React from 'react';

import { twMerge } from 'tailwind-merge';

export function IconButton({
  icon,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick = () => {},
  className = '',
  disabled,
}: {
  icon: React.ReactNode;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}) {
  return (
    <button
      type="button"
      className={twMerge(
        'bg-primary text-white font-bold px-2 py-2 rounded-full disabled:opacity-25 h-10 w-10',
        className
      )}
      onClick={() => onClick()}
      disabled={disabled}
    >
      {icon}
    </button>
  );
}

export default IconButton;
