import axios from 'axios';

const filterType = 'address%2Cpoi';

async function getLocationNameSuggestions({ text }: { text: string }) {
  const encodedLocationText = encodeURI(text);
  const datalist = await axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedLocationText}.json?proximity=ip&language=de&types=${filterType}&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
  );
  return datalist.data;
}

async function getGeoCoordinateLocation({ lat, lng }: { lat: number; lng: number }) {
  const datalist = await axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?language=de&types=${filterType}&limit=1&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
  );
  return datalist.data;
}

export default {
  getLocationNameSuggestions,
  getGeoCoordinateLocation,
};
