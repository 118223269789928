import React from 'react';

import { Combobox as TwCombobox } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export interface ComboboxOptionType {
  id: string;
  label: string;
  option?: any;
}

export function ComboxboxOption({ id, label, option }: ComboboxOptionType) {
  return (
    <TwCombobox.Option
      key={id}
      value={option}
      className={({ active }) =>
        twMerge(
          'relative cursor-default select-none py-2 pl-3 pr-9',
          active ? 'bg-primary text-white' : 'text-gray-900'
        )
      }
    >
      {({ active, selected }) => (
        <>
          <span className={twMerge('block truncate', selected && 'font-semibold')}>{label}</span>

          {selected && (
            <span
              className={twMerge(
                'absolute inset-y-0 right-0 flex items-center pr-4',
                active ? 'text-white' : 'text-primary'
              )}
            >
              <FontAwesomeIcon icon={faCheck} />
            </span>
          )}
        </>
      )}
    </TwCombobox.Option>
  );
}

export default ComboxboxOption;
