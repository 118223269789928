import React from 'react';
import CreateGroupLocationStep from './Steps/GroupCreationSetLocationStep';
import CreateGroupNameStep from './Steps/GroupCreationSetNameStep';
import CreateGroupOptionSelectionStep from './Steps/GroupCreationOptionSelectionStep';
import CreateGroupShareStep from './Steps/GroupCreationShareStep';

import { useGroupCreation } from '../../contexts/GroupCreationContext';

function GroupCreation() {
  const {
    creationStep,
    groupName,
    setGroupName,
    setVotingItems,
    createdGroupId,
    votingItems,
    isLoading,
    loadingError,
    locationSuggestions,
    setSearchLocation,
    setLocationSearchText,
    setNextCreationStep,
    location,
  } = useGroupCreation();

  if (creationStep === 0) {
    return <CreateGroupNameStep onSubmit={setGroupName} />;
  }

  if (creationStep === 1) {
    return (
      <CreateGroupLocationStep
        locationSuggestions={locationSuggestions}
        setSearchLocation={setSearchLocation}
        setLocationSearchText={setLocationSearchText}
        setNextCreationStep={setNextCreationStep}
        location={location}
      />
    );
  }

  if (creationStep === 2) {
    return (
      <CreateGroupOptionSelectionStep
        isLoading={isLoading}
        loadingError={loadingError}
        onSubmit={setVotingItems}
        votingItems={votingItems}
      />
    );
  }

  return <CreateGroupShareStep createdGroupId={createdGroupId} groupName={groupName || 'unbekannt'} />;
}

export default GroupCreation;
