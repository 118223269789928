import React from 'react';
import { twMerge } from 'tailwind-merge';

export function Button({
  name,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick = () => {},
  type = 'button',
  disabled = false,
  className = '',
}: {
  name: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  className?: string;
}) {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled}
      className={twMerge('bg-primary text-white text-lg font-bold py-2 px-4 rounded-lg disabled:opacity-25', className)}
      onClick={() => onClick()}
    >
      {name}
    </button>
  );
}

export default Button;
