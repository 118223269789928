/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Menu } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

export interface DropdownMenuItemType {
  label: string | React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

export function DropdownMenuItem({ label, onClick, disabled = false }: DropdownMenuItemType) {
  return (
    <Menu.Item disabled={disabled}>
      {({ active }) => (
        <div
          className={twMerge(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-lg')}
          onClick={onClick}
        >
          {label}
        </div>
      )}
    </Menu.Item>
  );
}

export default DropdownMenuItem;
