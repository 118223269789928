import React from 'react';
import { useField } from 'formik';

import SystemMessageVotingItem from './SystemMessageVotingItem';
import { VotingItem } from '../../models/VotingItem';

interface Props {
  votingItem: VotingItem;
}

function SystemMessageVotingItemWithFormik({ votingItem }: Props) {
  const [field, , helpers] = useField({ name: 'votingItems', type: 'checkbox', multiple: true });

  const handleBtnClick = () => {
    if (field.value.includes(votingItem.googlePlacesId)) {
      helpers.setValue(field.value.filter((id: string) => id !== votingItem.googlePlacesId));
    } else {
      helpers.setValue([...field.value, votingItem.googlePlacesId]);
    }
  };

  return (
    <SystemMessageVotingItem
      votingItem={votingItem}
      selected={field.value.includes(votingItem.googlePlacesId)}
      isActive
      btnText={['Hinzufügen', 'Entfernen']}
      onBtnClick={handleBtnClick}
    />
  );
}

export default SystemMessageVotingItemWithFormik;
