import React, { createContext, ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { VotingItem } from '../models/VotingItem';
import groupService from '../services/groupService';
import offerService from '../services/offerService';
import mapboxService from '../services/mapboxService';

interface GroupCreationContextInterface {
  creationStep: number;
  setNextCreationStep(): void;
  groupName: string;
  setGroupName({ groupName }: { groupName: string }): void;
  createdGroupId: string | null;
  setVotingItems({ votingItems }: { votingItems: string[] }): void;
  votingItems: VotingItem[];
  isLoading: boolean;
  loadingError: string | null;
  setSearchLocation(searchLocation: { lat: number; lng: number }): void;
  getLocationNameSuggestions({ text }: { text: string }): Promise<any>;
  locationSuggestions: { id: string; label: string; lat: number; lng: number }[];
  setLocationSearchText({ text }: { text: string }): void;
  location: { lat: number; lng: number } | null;
}

export const GroupCreationContext = createContext<GroupCreationContextInterface>({} as GroupCreationContextInterface);

type Props = {
  children: ReactElement;
};

export function GroupCreationContextProvider({ children }: Props) {
  const [creationStep, setCreationStep] = useState(0);
  const [groupName, setGroupName] = useState('');
  const [createdGroupId, setCreatedGroupId] = useState<string | null>(null);
  const [votingItems, setVotingItems] = useState<VotingItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState<string | null>(null);
  const [location, setLocation] = useState<{ lat: number; lng: number } | null>(null);
  const [locationSuggestions, setLocationSuggestions] = useState<
    { id: string; label: string; lat: number; lng: number }[]
  >([]);

  let locationSearchInterval: ReturnType<typeof setTimeout> | null = null;

  useEffect(() => {
    const fetchVotingItemsRelatedToGeolocation = async () => {
      setIsLoading(true);
      try {
        if (location) {
          const offers: VotingItem[] = await offerService.getOffersNearby({
            lat: location.lat,
            lng: location.lng,
          });

          setVotingItems(offers);
        } else {
          setLoadingError('Leider konnten wir deinen eingegebenen Standort nicht finden.');
        }
      } catch (e) {
        console.error(e);
        setLoadingError('Es ist ein Fehler aufgetreten.');
      }
      setIsLoading(false);
    };

    if (creationStep === 2) {
      fetchVotingItemsRelatedToGeolocation();
    }
  }, [creationStep]);

  const handleNextCreationStep = () => {
    setCreationStep((prev) => prev + 1);
  };

  const handleSetGroupName = ({ groupName }: { groupName: string }) => {
    setGroupName(groupName);
    handleNextCreationStep();
  };

  const handleSetVotingItems = ({ votingItems }: { votingItems: string[] }) => {
    createGroup({ votingItems });
  };

  const createGroup = async ({ votingItems: selectedVotingItems }: { votingItems: string[] }) => {
    try {
      const selectedVotingItemsWithFullDetails: any = selectedVotingItems.map((googlePlacesId) => {
        const votingItem = votingItems.find((votingItem) => votingItem.googlePlacesId === googlePlacesId);
        return votingItem;
      });
      const { groupId } = await groupService.create({
        name: groupName,
        votingItems: selectedVotingItemsWithFullDetails,
      });
      setCreatedGroupId(groupId);
      handleNextCreationStep();
    } catch (e) {
      console.error(e);
    }
  };

  const getLocationNameSuggestions = async ({ text }: { text: string }) => {
    let transformedSuggestions: any = [];
    try {
      const suggestions = await mapboxService.getLocationNameSuggestions({ text });

      transformedSuggestions = suggestions.features.map((feature: any) => ({
        id: feature.id,
        lng: feature.center[0],
        lat: feature.center[1],
        label: feature.place_name,
      }));
    } catch (e) {
      console.error(e);
    }
    return transformedSuggestions;
  };

  const handleSetSearchLocation = (searchLocation: { lat: number; lng: number }) => {
    setLocation(searchLocation);
  };

  const handleSetLocationSearchText = ({ text }: { text: string }) => {
    if (text.length > 2) {
      if (locationSearchInterval) {
        clearInterval(locationSearchInterval);
      }

      locationSearchInterval = setTimeout(async () => {
        const suggestions = await getLocationNameSuggestions({ text });
        setLocationSuggestions(suggestions);
      }, 500);
    }
  };

  const value = useMemo(
    () => ({
      votingItems,
      createdGroupId,
      creationStep,
      setNextCreationStep: handleNextCreationStep,
      groupName,
      setGroupName: handleSetGroupName,
      setVotingItems: handleSetVotingItems,
      isLoading,
      loadingError,
      setSearchLocation: handleSetSearchLocation,
      getLocationNameSuggestions,
      locationSuggestions,
      setLocationSearchText: handleSetLocationSearchText,
      location,
    }),
    [
      createdGroupId,
      creationStep,
      groupName,
      handleNextCreationStep,
      handleSetGroupName,
      handleSetVotingItems,
      votingItems,
      isLoading,
      loadingError,
      handleSetSearchLocation,
      getLocationNameSuggestions, // TODO: remove this
      locationSuggestions,
      handleSetLocationSearchText,
      location,
    ]
  );

  return <GroupCreationContext.Provider value={value}>{children}</GroupCreationContext.Provider>;
}

export const useGroupCreation = () => useContext(GroupCreationContext);
