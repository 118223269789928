import React from 'react';
import { faBars, faCheckToSlot, faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../atoms/Button';
import FirstHeadline from '../atoms/FirstHeadline';
import IconButton from '../atoms/IconButton';
import ThirdHeadline from '../atoms/ThirdHeadline';
import DropdownMenu from '../molecules/DropdownMenu';

interface Props {
  groupName: string | null;
  isCurrentVotingClosed: boolean;
  closeVoting(): void;
  toggleGroupShareModal(): void;
}

export function ChatHeader({ groupName, isCurrentVotingClosed, closeVoting, toggleGroupShareModal }: Props) {
  const Logo = (
    <a href="/">
      <FirstHeadline text="Decide Now" className="text-3xl sm:text-3xl lg:text-4xl m-1" />
    </a>
  );

  const ShareButton = (
    <IconButton icon={<FontAwesomeIcon icon={faShare} />} className="mr-3" onClick={toggleGroupShareModal} />
  );

  const closeVotingButton = <Button disabled={isCurrentVotingClosed} name="Abstimmung beenden" onClick={closeVoting} />;

  const GroupName = (
    <ThirdHeadline text={groupName || ''} className="text-lg sm:text-lg lg:text-xl m-1 font-medium tracking-wide" />
  );

  const menuItems = [
    { label: 'Teilen', icon: faShare, onClick: toggleGroupShareModal },
    { label: 'Abstimmung beenden', icon: faCheckToSlot, onClick: closeVoting, disabled: isCurrentVotingClosed },
  ];

  const MenuButton = (
    <div className="flex items-center">
      <DropdownMenu
        label={<FontAwesomeIcon size="2x" icon={faBars} />}
        items={menuItems.map((item: any) => ({
          label: (
            <div className="flex items-center text-lg">
              <div className="mr-3">
                <FontAwesomeIcon icon={item.icon} />
              </div>
              <p>{item.label}</p>
            </div>
          ),
          onClick: item.onClick,
          disabled: item.disabled,
        }))}
      />
    </div>
  );

  return (
    <div className="top-0 shadow-lg z-20">
      {/* small/medium devices */}
      <div className="block lg:hidden p-4">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col">
            {Logo}
            {GroupName}
          </div>
          {MenuButton}
        </div>
      </div>
      {/* large devices */}
      <div className="lg:block hidden p-4">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col">
            {Logo}
            {GroupName}
          </div>
          <div className="flex flex-row items-center">
            {ShareButton}
            {closeVotingButton}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatHeader;
