import { faCopy, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { TelegramIcon, TelegramShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { useGroupChat } from '../../contexts/GroupChatContext';

interface Props {
  onClose(): any;
  groupName: string | null;
}

function ShareGroupModal({ onClose, groupName }: Props) {
  const { innerHeight } = useGroupChat();
  const { groupId } = useGroupChat();
  const groupPath = `/chat/${groupId}`;
  const groupInvitationLink = `${window.location.origin}${groupPath}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(groupInvitationLink);
  };

  return (
    <div
      className=" absolute top-0 left-0 w-screen backdrop-blur-lg backdrop- flex justify-center items-center z-40"
      style={{ height: innerHeight }}
    >
      <div className="relative m-4 bg-white rounded-xl shadow-black shadow-2xl p-5 flex flex-col">
        <div className="w-full h-1/4 flex justify-between items-start text-xl text-primary font-bold mb-6">
          <div>{groupName}</div>
          <button type="button" onClick={() => onClose()} className="text-2xl text-primary">
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
        <div className="w-full flex-1 flex flex-col justify-center">
          <div>
            <button
              type="button"
              className="border border-primary rounded-xl flex-1 p-2 text-primary w-full break-all"
              onClick={copyToClipboard}
            >
              {groupInvitationLink}
            </button>
          </div>

          <div className="w-full flex flex-row justify-center mt-5">
            <div className="m-2">
              <WhatsappShareButton
                title={`Du wurdest zur Decide Now Gruppe '${groupName}' eingeladen. Klicke auf folgenden Link um der Gruppe beizutreten`}
                url={groupInvitationLink}
              >
                <WhatsappIcon size={48} round={!false} />
              </WhatsappShareButton>
            </div>
            <div className="m-2">
              <TelegramShareButton
                title={`Du wurdest zur Decide Now Gruppe '${groupName}' eingeladen. Klicke auf folgenden Link um der Gruppe beizutreten`}
                url={groupInvitationLink}
              >
                <TelegramIcon size={48} round={!false} />
              </TelegramShareButton>
            </div>
            <div className="m-2">
              <button
                type="button"
                className="border border-primary rounded-full h-12 aspect-square flex justify-center items-center"
                onClick={copyToClipboard}
              >
                <FontAwesomeIcon icon={faCopy} className="text-xl text-primary" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShareGroupModal;
