/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { uniqueId } from 'lodash';
import DropdownMenuItem, { DropdownMenuItemType } from '../atoms/DropdownMenuItem';

export interface DropdownType {
  label: string | React.ReactNode;
  items: DropdownMenuItemType[];
}

export function Dropdown({ label, items }: DropdownType) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-xl border border-gray-300 bg-white px-4 py-2 text-lg font-medium text-primary shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-100">
          {label}
        </Menu.Button>
      </div>

      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1 ">
            {items.map((option) => (
              <DropdownMenuItem
                key={uniqueId()}
                label={option.label}
                onClick={option.onClick}
                disabled={option?.disabled}
              />
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default Dropdown;
