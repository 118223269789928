import React from 'react';
import { twMerge } from 'tailwind-merge';

export function Text({
  text = '',
  children = '',
  className,
}: {
  text?: string;
  children?: string;
  className?: string;
}) {
  return <p className={twMerge('mt-1 mb-1 text-xl leading-8 text-gray-500', className)}>{text || children}</p>;
}

export default Text;
