import React from 'react';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WhatsappShareButton, WhatsappIcon, TelegramShareButton, TelegramIcon } from 'react-share';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/organisms/DefaultHeader';
import PageContainer from '../../../components/atoms/PageContainer';
import { Button } from '../../../components/atoms/Button';
import Footer from '../../../components/organisms/Footer';

function CreateGroupShareStep({
  createdGroupId,
  groupName,
}: {
  createdGroupId: string | null;
  groupName: string | null;
}) {
  const navigate = useNavigate();
  const groupPath = `/chat/${createdGroupId}`;
  const groupInvitationLink = `${window.location.origin}${groupPath}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(groupInvitationLink);
  };

  return (
    <>
      <PageContainer className="h-[80vh]">
        <div className="flex justify-end">
          <Button name="Zur Abstimmung" type="button" onClick={() => navigate(groupPath)} />
        </div>
        <a href="/">
          <Header title="Decide Now" subtitle={`Deine Abstimmung "${groupName}" ist bereit!`} />
        </a>
        <div className="flex flex-col justify-center">
          <div className="flex flex-row items-center">
            <button
              type="button"
              id="groupInvitationLinkBtn"
              className="border border-gray-300 shadow-lg rounded-xl flex-1 mx-2 p-2 text-lg text-primary"
              onClick={copyToClipboard}
            >
              {groupInvitationLink}
            </button>
          </div>
          <div className="w-full flex flex-row justify-center mt-5">
            <div className="m-2">
              <WhatsappShareButton
                title={`Du wurdest zur Decide Now Gruppe '${groupName}' eingeladen. Klicke auf folgenden Link um der Gruppe beizutreten`}
                url={groupInvitationLink}
              >
                <WhatsappIcon size={48} round={!false} />
              </WhatsappShareButton>
            </div>
            <div className="m-2">
              <TelegramShareButton
                title={`Du wurdest zur Decide Now Gruppe '${groupName}' eingeladen. Klicke auf folgenden Link um der Gruppe beizutreten`}
                url={groupInvitationLink}
              >
                <TelegramIcon size={48} round={!false} />
              </TelegramShareButton>
            </div>
            <div className="m-2">
              <button
                type="button"
                className="border border-primary rounded-full h-12 aspect-square flex justify-center items-center"
                onClick={copyToClipboard}
              >
                <FontAwesomeIcon icon={faCopy} color="#0085FF" className="text-xl" />
              </button>
            </div>
          </div>
        </div>
      </PageContainer>
      <Footer />
    </>
  );
}

export default CreateGroupShareStep;
