import axios from 'axios';

if (!process.env.REACT_APP_API_URL) throw new Error('FATAL ERROR: REACT_APP_API_URL is not defined.');

axios.interceptors.response.use(null, (error) => {
  const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;

  if (!expectedError) {
    console.error(error);
  }

  return Promise.reject(error);
});

export const { get, post, put, delete: remove, patch } = axios;

export default {
  get,
  post,
  put,
  remove,
  delete: remove,
  patch,
};
