import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGroupChat } from '../contexts/GroupChatContext';
import ChatTemplate from '../components/templates/ChatTemplate';

function Chat() {
  const { groupId } = useParams();
  const {
    connect,
    isNewToGroup,
    join,
    assembledChatHistory,
    message,
    memberId,
    refChatContainer,
    isScrollToChatEndInitialized,
    refChatMessageInput,
    isCurrentVotingClosed,
    closeVoting,
    vote,
    unvote,
    name: groupName,
    innerHeight,
    groupShareModalIsOpen,
    toggleGroupShareModal,
  } = useGroupChat();

  useEffect(() => {
    if (groupId) {
      connect(groupId);
    }
  }, [groupId]);

  useEffect(() => {
    if (isNewToGroup) {
      let yourName = prompt('Wie möchtest du dich nennen?');
      while (!yourName) {
        yourName = prompt('Bitte gebe ein wie du dich nennen möchtest!');
      }

      if (yourName) {
        join(yourName);
      }
    }
  }, [isNewToGroup]);

  return (
    <ChatTemplate
      assembledChatHistory={assembledChatHistory}
      message={message}
      memberId={memberId}
      innerHeight={innerHeight}
      isCurrentVotingClosed={isCurrentVotingClosed}
      toggleGroupShareModal={toggleGroupShareModal}
      refChatContainer={refChatContainer}
      isScrollToChatEndInitialized={isScrollToChatEndInitialized}
      groupName={groupName}
      closeVoting={closeVoting}
      vote={vote}
      unvote={unvote}
      refChatMessageInput={refChatMessageInput}
      groupShareModalIsOpen={groupShareModalIsOpen}
    />
  );
}

export default Chat;
