import React from 'react';
import { twMerge } from 'tailwind-merge';

export function FirstHeadline({ text, className = '' }: { text: string; className?: string }) {
  const classes = 'mt-1 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl mb-6';

  return <h1 className={twMerge(classes, className)}>{text}</h1>;
}

export default FirstHeadline;
