import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { VotingItem } from '../../models/VotingItem';
import { VotingItemBtn } from '../atoms';
import StarRating from '../atoms/StarRating';

interface Props {
  votingItem: VotingItem;
  selected?: boolean;
  btnText?: string | string[];
  isActive: boolean;
  onBtnClick?: () => void;
}

function SystemMessageVotingItem({ votingItem, selected, btnText, isActive, onBtnClick }: Props) {
  const renderPriceRange = (priceRange: number) => {
    let priceStr = '';
    for (let i = 0; i < priceRange; i += 1) {
      priceStr += '€';
    }
    return priceStr;
  };

  const defaultImageUrl = `${process.env.REACT_APP_API_URL}/offers/photo_reference/null`;

  return (
    <div className="m-3">
      <div className="mx-auto bg-white rounded-lg overflow-hidden text-primaryDeepDark relative max-w-[500px] shadow-lg aspect-[2/1]">
        <div className="w-full h-full  flex flex-row">
          <div className="w-[60%] sm:w-[50%] md:w-[40%] h-full flex flex-col py-2 pl-4 pr-2 text-sm leading-5 ">
            <div className="w-full grow flex flex-col justify-around  text-center">
              <div className="w-full font-bold my-2 text-base text-center leading-[1.1rem] line-clamp-2">
                {votingItem?.name}
              </div>
              <div className="w-full">
                <StarRating rating={votingItem?.rating} />
                {votingItem?.rating}/5 ({votingItem?.userRatingsTotal}) by <FontAwesomeIcon icon={faGoogle} />
              </div>
              {votingItem.priceLevel ? <div className="w-full">{renderPriceRange(votingItem.priceLevel)}</div> : ''}
              <div className="w-full">
                <a
                  className="w-full h-full flex justify-start items-start text-blue-600 hover:underline line-clamp-2"
                  href={`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${votingItem.googlePlacesId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {votingItem.vicinity}
                </a>
              </div>
            </div>
          </div>
          <div className="relative w-[40%] sm:w-[50%] md:w-[60%] h-full overflow-hidden">
            <img src={votingItem.mainImageUrl || defaultImageUrl} alt="" className="object-cover w-full h-full" />
            <div className="absolute w-[60%] sm:w-[50%] md:w-[40%] h-full left-0 top-0 bg-gradient-to-l from-transparent to-white" />
          </div>
          <div className="absolute right-5 bottom-3">
            <VotingItemBtn selected={selected} btnText={btnText} onBtnClick={onBtnClick} disabled={!isActive} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SystemMessageVotingItem;
