import React, { useState } from 'react';
import { faUpDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Combobox as TwCombobox } from '@headlessui/react';
import ComboxboxOption, { ComboboxOptionType } from '../atoms/ComboboxOption';

export function Combobox({
  label,
  options,
  onSelect,
  onInput,
}: {
  label?: string;
  options: ComboboxOptionType[];
  onSelect?: (option: any) => void;
  onInput?: (event: any) => void;
}) {
  // const [query, setQuery] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);

  const handleSelect = (option: any) => {
    setSelectedItem(option);
    // setQuery(option.label);
    if (typeof onSelect === 'function') onSelect(option);
  };

  const handleInput = (event: any) => {
    // setQuery(event.target.value);
    if (typeof onInput === 'function') onInput({ text: event.target.value });
  };

  // const filteredOptions =
  //   query === '' ? options : options.filter((option) => option.label.toLowerCase().includes(query.toLowerCase()));

  // 'block w-full rounded-xl border border-gray-300 shadow-lg focus:border-primary focus:ring-primary text-lg py-2 px-4',

  return (
    <TwCombobox as="div" value={selectedItem} onChange={handleSelect}>
      {label && <TwCombobox.Label className="block text-sm font-medium text-gray-700">{label}</TwCombobox.Label>}
      <div className="relative mt-1">
        <TwCombobox.Input
          className="w-full rounded-xl border border-gray-300 py-2 pl-4 pr-10 shadow-lg focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary text-lg"
          onChange={handleInput}
          displayValue={(option: any) => option?.label}
        />
        <TwCombobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <FontAwesomeIcon icon={faUpDown} />
        </TwCombobox.Button>

        {options.length > 0 && (
          <TwCombobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-lg">
            {options.map((option) => (
              <ComboxboxOption key={option.id} id={option.id} label={option.label} option={option} />
            ))}
          </TwCombobox.Options>
        )}
      </div>
    </TwCombobox>
  );
}

export default Combobox;
