import React from 'react';
import { Text } from './Text';

export function ChatItemInformation({ information }: { information?: string }) {
  return (
    <div className="flex items-center justify-center m-3">
      <div className="border p-1 rounded-lg shadow">
        <Text className="m-0 p-0 text-md font-light">{information}</Text>
      </div>
    </div>
  );
}

export default ChatItemInformation;
