import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import SystemMessageVotingItemWithFormik from '../../../components/molecules/SystemMessageVotingItemWithFormik';
import { VotingItem } from '../../../models/VotingItem';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { Button } from '../../../components/atoms/Button';
import Header from '../../../components/organisms/DefaultHeader';
import Footer from '../../../components/organisms/Footer';
import Spinner from '../../../components/atoms/Spinner';

function CreateGroupOptionSelectionStep({
  onSubmit,
  votingItems,
  isLoading,
  loadingError,
}: {
  onSubmit({ votingItems }: { votingItems: string[] }): void;
  votingItems: VotingItem[];
  isLoading: boolean;
  loadingError: string | null;
}) {
  return (
    <Formik
      initialValues={{ votingItems: [] }}
      onSubmit={(values) => {
        onSubmit(values);
      }}
      validationSchema={Yup.object().shape({
        votingItems: Yup.array().min(2, 'Bitte wähle mindestens 2 Optionen aus'),
      })}
    >
      {({ errors, touched }) => {
        const hasError = Boolean(errors.votingItems && touched.votingItems);

        return (
          <Form>
            <PageContainer className="h-[80vh]">
              <div className="flex justify-end">
                <Button name="Weiter" type="submit" disabled={hasError} />
              </div>
              <a href="/">
                <Header title="Decide Now" subtitle="Welche Optionen kommen für dich in Frage?" />
              </a>
              {hasError && (
                <div className="mb-4 text-base text-red-600 basis-full text-center">{errors.votingItems}</div>
              )}

              {isLoading && (
                <div className="flex justify-center">
                  <Spinner />
                </div>
              )}

              {!isLoading && loadingError && (
                <div className="mb-4 text-base text-red-600 basis-full text-center">{loadingError}</div>
              )}

              {!isLoading && !loadingError && votingItems.length === 0 && (
                <div className="mb-4 text-base text-red-600 basis-full text-center">
                  Leider können wir dir in deiner Umgebung keine Optionen anbieten.
                </div>
              )}

              {!isLoading && !loadingError && votingItems.length > 0 && (
                <div className="flex flex-col flex-1 w-full overflow-scroll mb-8 ">
                  {votingItems.map((votingItem: VotingItem) => (
                    <SystemMessageVotingItemWithFormik votingItem={votingItem} key={votingItem.googlePlacesId} />
                  ))}
                </div>
              )}

              <Footer />
            </PageContainer>
          </Form>
        );
      }}
    </Formik>
  );
}

export default CreateGroupOptionSelectionStep;
